

import { ref } from "vue"

export default {
  name: "Tabbar",
  setup () {
    const active = ref(0)
    const icon = {
      homeIcon: {
        active: require("@/assets/icon_tabbar_hp.png"),
        inactive: require("@/assets/icon_tabbar_hp_n.png")
      },
      mineIcon: {
        active: require("@/assets/icon_tabbar_mine.png"),
        inactive: require("@/assets/icon_tabbar_mine_n.png")
      }
    }
    return {
      active,
      icon
    }
  }
}

